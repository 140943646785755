import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9f307f74")
const _hoisted_1 = { class: "operation-wrapper" }
_popScopeId()

import { onMounted, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import dayjs from 'dayjs'
import { RouterName } from '@/enum'
import utc from 'dayjs/plugin/utc'
import OpenAccount from './components/openAccount.vue'
import { queryFnByFilter } from '@/graphql/index'


export default _defineComponent({
  setup(__props) {

dayjs.extend(utc)

onBeforeRouteLeave(to => {
  const { name } = to
  if (name === RouterName.TRANSFER_PRO) {
    return false
  }
  return true
})
const imgUrl = ref()
const drafts = ref({
  openImmigrantAccount: '',
  openAccount: ''
})

const queryBgImgUrl = (val: string) => {
  imgUrl.value = val
}

// 获取草稿
const queryAllFormTypeDrafts = async () => {
  const res = await queryFnByFilter('allFormTypeDrafts', {
    meta: 'open_common'
  })
  if (res?.ok) {
    drafts.value.openImmigrantAccount = res.data.find((v: { formType: string }) => v.formType === 'openImmigrantAccount')?.content
    drafts.value.openAccount = res.data.find((v: { formType: string }) => v.formType === 'openAccount')?.content
  }
}

onMounted(() => {
  queryAllFormTypeDrafts()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "wrap",
    style: _normalizeStyle({ backgroundImage: 'url(' + imgUrl.value + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(OpenAccount, {
        text: "开通投资移民账户",
        icon: "投资移民开户",
        type: "openImmigrantAccount",
        openCommon: drafts.value.openImmigrantAccount,
        onQueryBgImgUrl: queryBgImgUrl
      }, null, 8, ["openCommon"]),
      _createVNode(OpenAccount, {
        text: "开通普通账户",
        icon: "普通开户",
        type: "openAccount",
        openCommon: drafts.value.openAccount,
        onQueryBgImgUrl: queryBgImgUrl
      }, null, 8, ["openCommon"])
    ])
  ], 4))
}
}

})